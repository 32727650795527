@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
  --purple: #6B24F7;
  --gren_main: #35D172;
}

@layer utilities {
  .tooltip {
    display: inline-block;
    border-bottom: 1px dotted #ccc;
    color: #006080;
    font-size: 15px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    max-width: 420px;
    min-width: 300px;
    background-color: var(--gren_main);
    color: #fff;
    text-align: center;
    padding: 5px 1px;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-bottom {
    /*top: 135%;*/
    left: 42%;
    margin-left: -150px;
    margin-top: 10px;
  }

  .tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent  var(--gren_main) transparent;
  }
}

.rccs__card {
  width: 100% !important;
}
.rccs{
  width: 100% !important;
}

.switch-main > .MuiSwitch-track {
  border-radius: 15px !important;
  background-color: lightgray;
  opacity: initial;
}

.switch-main {
  padding: 6px !important;
}

.MuiSwitch-thumb {
  width: 17px !important;
  height: 17px !important;
  background-color: white !important;
  margin-top: 1px;
}

.phone-input  > input::placeholder {
  color: red;
}

body {
  padding-right: 0 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.rccs__card--unknown > div {
  background: linear-gradient(25deg, #773dea, #6b24f7) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  max-width: 420px !important;
  margin: 0 auto !important;
  background: #f9f9f9 !important;
}
